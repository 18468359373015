<template>
  <div class="role-detail">

    <div class="form">
      <el-form label-width="120px"
               :hide-required-asterisk="true"
               ref="form"
               size="small"
               @submit.native.prevent
               :model="form"
               :rules="rules" >
        <div class="content">
          <el-form-item label="角色名称" prop="roleName">
            <el-input v-model="form.roleName" clearable placeholder="请输入角色名称"></el-input>
          </el-form-item>
          <el-form-item label="角色描述" prop="roleDesc">
            <el-input v-model="form.roleDesc" clearable placeholder="请输入角色描述"></el-input>
          </el-form-item>
          <el-form-item label="权限" prop="checkedMenuId">
            <el-tree
                :data="tree_data"
                :default-checked-keys="form.checkedMenuId"
                show-checkbox
                default-expand-all
                node-key="id"
                ref="tree"
                highlight-current
                :props="defaultProps">
            </el-tree>
          </el-form-item>

        </div>
        <el-form-item>
          <div style="display: flex; justify-content: flex-end; ">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" native-type="submit" @click="onSubmit(form)">提交</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
export default {
  name: "RoleDetail",
  data() {
    return {
      form: {
        id: null,
        roleName: null,
        roleDesc: null,
        checkedMenuId: []
      },
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
        ],
        checkedMenuId: [
          { required: true, message: '请选择角色权限', trigger: 'blur' },
        ]
      },
      tree_data: [],
      defaultProps: {
        children: 'children',
        label: 'menuName'
      },
      childrenListId: []
    }
  },
  created() {
    this.getAllMenu();
  },
  mounted() {

  },
  methods: {
    getChildrenListId(e) {
      e.forEach(item => {
        if(item.children == null) {
          this.childrenListId.push(item.id);
        }else {
          this.getChildrenListId(item.children);
        }
      })
    },
    getDetail(e) {
      this.$get("Role/returnRoleInfo",e)
        .then(res => {
          if(res) {
            console.log(res);
            this.form= res.data;
          }
        })
    },
    // 获取所有菜单
    getAllMenu() {
      this.$get("Role/getAllMenu")
        .then(res => {
          if(res) {
            console.log(res);
            this.tree_data= res.data;
            this.getChildrenListId(this.tree_data);

            if(this.$route.query.id) {
              this.getDetail({ roleId: this.$route.query.id });
            }
          }
        })
    },
    onSubmit(e) {
      let checkedKeysList= this.$refs.tree.getCheckedKeys();
      let checkedMenuId= [];
      checkedKeysList.forEach(item => {
        if(this.childrenListId.includes(item)) {
          checkedMenuId.push(item);
        }
      })
      e.checkedMenuId= checkedMenuId;
      console.log(e.checkedMenuId);
      this.$refs["form"].validate((valid) => {
        if(valid) {

          this.$post("Role",e)
            .then(res => {
              if(res) {
                console.log(res)
                this.$message.success(res.message);
                this.$store.dispatch("setRole");
                this.$router.go(-1);
              }
            })

        }
      })
    },
    cancel() {
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
 @import "../../style/System/RoleDetail";
</style>
